import {
  StoreName,
  withError,
  withLoaded,
  withLoading,
} from 'src/app/core/store/store.config';

import { createStore, withProps } from '@ngneat/elf';
import {
  withActiveId,
  withEntities,
  withUIEntities,
} from '@ngneat/elf-entities';

import { Run, RunUI } from './run.model';

export const INITIAL_RUN_STORE_PROPS = {
  validatingRun: false,
  canLaunchRun: false,
  hasValidationErrors: false,
};

export const runStore = createStore(
  { name: StoreName.Run },
  withEntities<Run, 'uuid'>({ idKey: 'uuid' }),
  withUIEntities<RunUI, 'uuid'>({ idKey: 'uuid' }),
  withActiveId(),
  withLoading(),
  withLoaded(),
  withError(),
  withProps(INITIAL_RUN_STORE_PROPS),
);
